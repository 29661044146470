.HomeBlogs {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: sticky;

    .wrapper {
        width: 100%;
        height: 100%;
        padding-top: 10px;
        padding: 50px 13vw;

        @media(max-width:1350px) {
            padding-top: 10px;
            padding: 50px 11vw;
        }

        @media(max-width:900px) {
            padding: 50px 8vw;
            padding-top: 10px;
        }

        @media(max-width:500px) {
            padding-top: 0;
            padding: 30px 5vw;
        }

        .section {
            padding: 2vw;
            border-radius: 20px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            background-color: #64646422;

            @media(max-width:500px) {
                padding: 3vw;
                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
            }
        }

        .title {
            text-align: center;
        }

        .cards {
            display: flex;
            padding: 2.5vw;
            flex-wrap: wrap;
            // align-items: center;
            justify-content: center;

            @media (max-width:1100px) {
                padding: 0;
                margin-top: 4vw;
            }

            @media (max-width:767px) {
                display: grid;
                grid-template-columns: repeat(2, 2fr);
            }

            .new {
                padding: 1.2vw;
                perspective: 800px;

                @media (max-width:767px) {
                    width: 100%;
                    padding: 1.6vw;
                }

                .body {
                    overflow: hidden;
                    position: relative;
                    height: 100%;
                    border-radius: 1.1vw;
                    transform-style: preserve-3d;
                    -webkit-border-radius: 1.1vw;
                    -moz-border-radius: 1.1vw;
                    -ms-border-radius: 1.1vw;
                    -o-border-radius: 1.1vw;
                    background-color: #64646426;

                    @media (max-width:767px) {
                        border-radius: 1.4vw;
                    }

                    @media (max-width:500px) {
                        border-radius: 2.5vw;
                    }

                    .stamp {
                        top: 20px;
                        right: 20px;
                        color: white;
                        font-size: 10px;
                        padding: 3px 8px;
                        position: absolute;
                        border-radius: 7px;
                        text-transform: uppercase;
                        -webkit-border-radius: 7px;
                        -moz-border-radius: 7px;
                        -ms-border-radius: 7px;
                        -o-border-radius: 7px;
                        background-color: #a3a3a394;

                        @media (max-width:500px) {
                            top: 10px;
                            right: 10px;
                            font-size: 9px;
                        }
                    }

                    .basic-img {
                        position: relative;

                        &:hover {
                            .fa-search {
                                opacity: 1;
                            }
                        }

                        .fa-search {
                            top: 0;
                            left: 0;
                            z-index: 1;
                            opacity: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            font-size: 3vw;
                            color: white;
                            cursor: pointer;
                            transition: 0.3s;
                            position: absolute;
                            border-radius: 12px;
                            align-items: center;
                            justify-content: center;
                            background-color: rgba(0, 0, 0, 0.424);

                            @media (max-width:700px) {
                                font-size: 7vw;
                                border-radius: 8px;
                                -webkit-border-radius: 8px;
                                -moz-border-radius: 8px;
                                -ms-border-radius: 8px;
                                -o-border-radius: 8px;
                            }
                        }
                    }

                    img {
                        max-width: 100%;
                        min-width: 100%;
                        max-height: 13vw;
                        min-height: 13vw;

                        @media (max-width:767px) {
                            max-height: 20vw;
                            min-height: 20vw;
                        }

                        @media (max-width:500px) {
                            max-height: 24vw;
                            min-height: 24vw;
                        }
                    }

                    .texts {
                        padding: 1.2vw;
                        position: relative;

                        @media (max-width:767px) {
                            padding: 1.5vw;
                        }

                        @media (max-width:500px) {
                            padding: 2vw;
                        }

                        .name {
                            font-size: 1vw;
                            text-align: left;

                            @media (max-width:1100px) {
                                font-size: 1.2vw;
                                margin-bottom: 5px;
                            }

                            @media (max-width:767px) {
                                font-size: 1.9vw;
                                margin-bottom: 1.3vw;
                            }

                            @media (max-width:500px) {
                                font-size: 2.9vw;
                                margin-bottom: 1.3vw;
                            }
                        }

                        .desc {
                            font-size: 1.2vw;
                            line-height: 26px;

                            @media (max-width:1100px) {
                                font-size: 1.4vw;
                                line-height: 23px;
                            }

                            @media (max-width:767px) {
                                font-size: 2.3vw;
                            }

                            @media (max-width:500px) {
                                font-size: 2.9vw;
                                line-height: 20px;
                            }
                        }

                        .explore {
                            top: -2vw;
                            width: 4vw;
                            z-index: 3;
                            height: 4vw;
                            left: 1.2vw;
                            display: flex;
                            padding: 0.3vw;
                            border-radius: 50%;
                            position: absolute;
                            align-items: center;
                            transition: 2s linear;
                            text-decoration: none;
                            justify-content: center;
                            transform: rotate(-180deg);

                            @media (max-width:767px) {
                                width: 7vw;
                                height: 7vw;
                                top: -3.5vw;
                                padding: 0.6vw;
                            }

                            .fa-angle-right {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                color: black;
                                font-size: 2.5vw;
                                border-radius: 50%;
                                align-items: center;
                                justify-content: center;
                                background-color: white;

                                @media (max-width:767px) {
                                    font-size: 5vw;
                                }
                            }
                        }

                        .rotate {
                            transform: rotate(360deg);
                            left: calc(100% - 5.2vw);

                            @media (max-width:767px) {
                                left: calc(100% - 8.2vw);
                            }
                        }

                        .bottom {
                            opacity: 0.4;
                            display: flex;
                            margin-top: 0.9vw;
                            align-items: center;
                            justify-content: start;

                            @media (max-width:767px) {
                                margin-top: 1.3vw;
                            }

                            .date,
                            .views {
                                margin: 0;
                                padding: 0;
                                font-size: 0.9vw;

                                @media (max-width:1100px) {
                                    font-size: 1.1vw;
                                }

                                @media (max-width:767px) {
                                    font-size: 1.8vw;
                                }

                                @media (max-width:500px) {
                                    font-size: 2.6vw;
                                }
                            }

                            .point {
                                width: 3.5px;
                                height: 3.5px;
                                margin: 0 10px;
                                border-radius: 50%;
                                background-color: black;
                            }
                        }
                    }
                }
            }
        }

        .more {
            width: 100%;
            display: flex;
            margin-top: 2vw;
            overflow: hidden;
            align-items: center;
            justify-content: center;

            @media (max-width:767px) {
                margin-top: 4vw;
                font-size: 14px;
            }

            .button {
                border: 0;
                padding: 8px;
                display: flex;
                cursor: pointer;
                position: relative;
                align-items: center;
                border-radius: 40px;
                -webkit-border-radius: 40px;
                -moz-border-radius: 40px;
                -ms-border-radius: 40px;
                -o-border-radius: 40px;
                background-color: #64646426;
                justify-content: space-between;
                &:hover{
                    color: white;
                    background-color: black;
                    .right-icon{
                        color: black;
                    }
                }

                @media(max-width:500px) {
                    padding: 5px;
                    border-radius: 30px;
                    -webkit-border-radius: 30px;
                    -moz-border-radius: 30px;
                    -ms-border-radius: 30px;
                    -o-border-radius: 30px;
                }

                &:hover {
                    .second {
                        top: 0;
                    }
                }

                .right-icon {
                    width: 50px;
                    height: 50px;
                    padding: 12px;
                    border-radius: 50%;
                    background-color: white;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;

                    @media(max-width:500px) {
                        width: 37px;
                        height: 37px;
                        padding: 9px;
                    }
                }

                p {
                    margin: 0 20px;
                    font-size: 18px;
                    text-transform: uppercase;

                    @media(max-width:500px) {
                        font-size: 14px;
                        margin: 0 15px;
                    }
                }

                .second {
                    top: -100%;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    transition: 0.3s linear;
                    background-color: rgb(255, 255, 255);
                }
            }
        }
    }
}