.FullImageModal{
    top: 0;
    left: 0;
    z-index: 21;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    padding: 50px 10vw;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.85);
    @media (max-width:767px){
        padding: 20px;
    }
    img{
        max-width: 100%;
        min-width: auto;
        max-height: 100%;
        min-height: 100%;
        object-fit: cover;
        @media (max-width:767px) {
            min-width: 100%;
            min-height: 70%;
        }
    }
}