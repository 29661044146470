.Footer{
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 5;
    position: sticky;
    background-color: black;
    .wrapper{
        @media(max-width:1150px){
            padding: 50px 13vw;
        }
        @media(max-width:1000px){
            padding: 50px 10vw;
        }
        .footer-tools{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            @media(max-width:700px){
                display: grid;
                grid-template-columns: repeat(2, 2fr);
            }
            .tool{
                padding: 30px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                @media(max-width:1450px){                            
                    padding: 30px 15px;
                }
                @media(max-width:1100px){                            
                    padding: 20px 10px;
                }
                @media(max-width:1000px){                            
                    padding: 10px;
                }
                @media(max-width:700px){
                    width: 100%;
                    padding: 10px 15px;
                    margin-bottom: 30px;
                }
                @media(max-width:500px){                    
                    padding: 10px;
                }
                @media(max-width:370px){                    
                    padding: 5px;
                }
                img{
                    width: 100%;
                }
                .icons{
                    display: flex;
                    margin-top: 30px;
                    align-items: center;
                    justify-content: start;
                    .fa{
                        color: white;
                        font-size: 35px;
                        margin-right: 50px;
                        @media(max-width:1350px){                            
                            margin-right: 40px;
                        }
                        @media(max-width:1150px){                            
                            font-size: 30px;
                            margin-right: 35px;
                        }
                        @media(max-width:900px){                            
                            font-size: 25px;
                            margin-right: 30px;
                        }
                    }
                }
                ul{
                    margin: 0;
                    padding: 0;                    
                    li{
                        margin: 0;
                        padding: 0;
                        color: white;
                        list-style: none;                        
                        @media(max-width:1350px){                            
                            font-size: 14px;
                        }
                        @media(max-width:1100px){                            
                            font-size: 13px;
                        }
                        @media(max-width:500px){                            
                            font-size: 12px;
                        }
                    }
                    .title-footer{
                        margin-bottom: 2vw;
                    }
                    .link{
                        opacity: 0.7;
                        margin-top: 20px;
                        &:hover{
                            text-decoration: underline;
                        }
                        @media(max-width:1350px){                            
                            font-size: 14px;
                        }
                        @media(max-width:1100px){                            
                            font-size: 13px;
                        }
                        @media(max-width:500px){                            
                            font-size: 12px;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
        .copywrite{
            display: flex;
            margin-top: 20px;
            padding-top: 40px;
            align-items: center;
            padding-bottom: 30px;
            justify-content: space-between;
            border-top: solid 1px rgba(255, 255, 255, 0.359);
            @media(max-width:1100px){                            
                padding-bottom: 20px;
            }
            @media(max-width:1000px){                            
                padding-bottom: 10px;
            }
            p{
                margin: 0;
                padding: 0;
                color: white;
                @media(max-width:1350px){                            
                    font-size: 14px;
                }
                @media(max-width:1100px){                            
                    font-size: 13px;
                }
                @media(max-width:600px){                            
                    font-size: 11px;
                }
            }
            .reserve{
                @media(max-width:500px){                            
                    display: none;
                }
            }
            .developed{
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media(max-width:500px){                            
                    width: 100%;
                }
                img{
                    margin-left: 30px;
                    @media(max-width:1100px){                            
                        width: 50px;
                    }
                    @media(max-width:700px){   
                        width: 45px;                         
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}