.Location{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;
    .wrapper{
        .adress{
            padding: 50px;
            display: flex;
            justify-content: space-between;
            border-radius: 20px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            background-color: #64646422;
            @media (max-width:1000px) {
                padding: 35px;
                border-radius: 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
            } 
            @media (max-width:767px) {
                padding: 25px;
                display: block;
                border-radius: 12px;
                -webkit-border-radius: 12px;
                -moz-border-radius: 12px;
                -ms-border-radius: 12px;
                -o-border-radius: 12px;
            }
            @media (max-width:600px) {
                padding: 20px;
                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
            } 
            @media (max-width:450px) {
                padding: 15px;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                -ms-border-radius: 8px;
                -o-border-radius: 8px;
            } 
            .img{
                padding-right: 20px;
                @media (max-width:767px) {
                    width: 100%;
                    padding-right: 0;
                }
                img{
                    max-width: 100%;
                    min-width: 100%;                    
                }
                iframe{
                    max-width: 100%;
                    min-width: 100%;
                    max-height: 20vw;
                    min-height: 20vw;
                    border-radius: 10px;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -ms-border-radius: 10px;
                    -o-border-radius: 10px;
                    @media (max-width:767px) {
                        max-height: 45vw;
                        min-height: 45vw;
                    }
                    @media (max-width:500px) {
                        max-height: 55vw;
                        min-height: 55vw;
                        border-radius: 7px;
                        -webkit-border-radius: 7px;
                        -moz-border-radius: 7px;
                        -ms-border-radius: 7px;
                        -o-border-radius: 7px;
                    }
                }
            }
            .texts{
                padding-left: 20px;
                @media (max-width:767px) {
                    width: 100%;
                    padding-left: 0;
                    margin-top: 20px;
                }
                @media (max-width:500px) {                    
                    margin-top: 10px;
                }
                .title{                    
                    text-align: left;                    
                }
                p{
                    @media (max-width:767px){
                        font-size: 14px;
                        margin-bottom: 3px;
                    }
                    @media (max-width:600px){
                        font-size: 13px;
                        line-height: 15px;
                        margin-bottom: 3px;
                    }                    
                }
            }
        }
    }
}