.SingleProduct {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;

    .wrapper {
        padding-top: 150px;

        @media (max-width:1350px) {
            padding-top: 130px;
        }

        @media (max-width:900px) {
            padding-top: 100px;
        }

        .links {
            display: flex;
            align-items: center;
            justify-content: start;

            a {
                opacity: 0.5;
                color: black;
                text-decoration: none;

                @media (max-width:500px) {
                    font-size: 13px;
                }

                &:nth-child(3) {
                    opacity: 1;
                    margin-left: 5px;
                }
            }
        }

        .cards {
            display: flex;
            margin-top: 3vw;
            align-items: center;
            justify-content: center;

            @media (max-width:800px) {
                display: block;
                margin-top: 4vw;
            }

            .texts {
                padding: 50px;
                display: flex;
                border-radius: 30px;
                padding-right: 7.3vw;
                margin-right: -5.2vw;
                flex-direction: column;
                align-items: flex-start;
                background-color: #ECECEC;
                justify-content: space-between;

                @media (max-width:1200px) {
                    padding: 2.3vw;
                    padding-right: 7.3vw;
                }

                @media (max-width:800px) {
                    width: 100%;
                    padding: 30px;
                    border-radius: 17px;
                    -webkit-border-radius: 17px;
                    -moz-border-radius: 17px;
                    -ms-border-radius: 17px;
                    -o-border-radius: 17px;
                }

                @media (max-width:500px) {
                    padding: 20px;
                    border-radius: 13px;
                    -webkit-border-radius: 13px;
                    -moz-border-radius: 13px;
                    -ms-border-radius: 13px;
                    -o-border-radius: 13px;
                }

                .name {
                    font-family: CraftWork600;
                    &::first-letter{
                        text-transform: uppercase;
                    }
                    @media (max-width:500px) {
                        font-size: 22px;
                    }

                    @media (max-width:400px) {
                        font-size: 20px;
                        margin-bottom: 0;
                    }
                }

                .desc {
                    margin-top: 10px;
                    font-family: CraftWork500;

                    @media (max-width:1200px) {
                        font-size: 14px;
                    }

                    @media (max-width:400px) {
                        font-size: 13px;
                        margin-bottom: 0;
                    }
                }

                .size {
                    width: 100%;
                    display: flex;
                    margin-top: 15px;
                    overflow: hidden;
                    padding-left: 20px;
                    align-items: center;
                    border-radius: 12px;
                    background-color: white;
                    font-family: CraftWork500;
                    justify-content: space-between;
                    color: rgba(80, 80, 80, 0.814);

                    @media (max-width:500px) {
                        font-size: 14px;
                        margin-top: 10px;
                        padding-left: 15px;
                        border-radius: 9px;
                        -webkit-border-radius: 9px;
                        -moz-border-radius: 9px;
                        -ms-border-radius: 9px;
                        -o-border-radius: 9px;
                    }

                    .value {
                        color: black;
                        font-weight: 500;
                        padding: 10px 50px;
                        border-radius: 12px;
                        // background-color: rgba(0, 0, 0, 0.763);

                        @media (max-width:500px) {
                            padding: 8px 30px;
                            border-radius: 9px;
                            -webkit-border-radius: 9px;
                            -moz-border-radius: 9px;
                            -ms-border-radius: 9px;
                            -o-border-radius: 9px;
                        }
                    }
                }

                .buy {
                    width: 100%;
                    border: none;
                    outline: none;
                    color: white;
                    padding: 20px 0;
                    margin-top: 4vw;
                    border-radius: 21px;
                    background-color: black;

                    @media (max-width:500px) {
                        font-size: 15px;
                        padding: 15px 0;
                        margin-top: 30px;
                        border-radius: 13px;
                        -webkit-border-radius: 13px;
                        -moz-border-radius: 13px;
                        -ms-border-radius: 13px;
                        -o-border-radius: 13px;
                    }
                }
            }

            .imgs {
                padding: 30px;
                border-radius: 30px;
                background-color: #DEDEDE;

                @media (max-width:800px) {
                    width: 100%;
                    margin-top: 3vw;
                    border-radius: 17px;
                    -webkit-border-radius: 17px;
                    -moz-border-radius: 17px;
                    -ms-border-radius: 17px;
                    -o-border-radius: 17px;
                }

                @media (max-width:500px) {
                    padding: 20px;
                    border-radius: 13px;
                    -webkit-border-radius: 13px;
                    -moz-border-radius: 13px;
                    -ms-border-radius: 13px;
                    -o-border-radius: 13px;
                }

                .basic-img {
                    position: relative;

                    &:hover {
                        .fa-search {
                            opacity: 1;
                        }
                    }

                    .fa-search {
                        top: 0;
                        left: 0;
                        z-index: 2;
                        opacity: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        font-size: 5vw;
                        color: white;
                        cursor: pointer;
                        transition: 0.3s;
                        position: absolute;
                        border-radius: 12px;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba(0, 0, 0, 0.424);

                        @media (max-width:700px) {
                            font-size: 10vw;
                            border-radius: 8px;
                            -webkit-border-radius: 8px;
                            -moz-border-radius: 8px;
                            -ms-border-radius: 8px;
                            -o-border-radius: 8px;
                        }
                    }
                }

                .main-image {
                    max-width: 100%;
                    min-width: 100%;
                    max-height: 22vw;
                    min-height: 22vw;
                    object-fit: cover;
                    border-radius: 12px;

                    @media (max-width:1200px) {
                        max-height: 24vw;
                        min-height: 24vw;
                    }

                    @media (max-width:800px) {
                        max-height: 43vw;
                        min-height: 43vw;
                        border-radius: 8px;
                        -webkit-border-radius: 8px;
                        -moz-border-radius: 8px;
                        -ms-border-radius: 8px;
                        -o-border-radius: 8px;
                    }
                }

                .other-imgs {
                    display: flex;
                    margin-top: 2vw;
                    align-items: center;
                    justify-content: center;

                    .col-4 {
                        &:nth-child(1) {
                            padding-right: 1.5vw;
                        }

                        &:nth-child(2) {
                            padding: 0 0.75vw;
                        }

                        &:nth-child(3) {
                            padding-left: 1.5vw;
                        }

                        .body {
                            height: 8vw;
                            cursor: pointer;
                            overflow: hidden;
                            position: relative;
                            border-radius: 20px;
                            -webkit-border-radius: 20px;
                            -moz-border-radius: 20px;
                            -ms-border-radius: 20px;
                            -o-border-radius: 20px;

                            @media(max-width:800px) {
                                height: 18vw;
                            }

                            @media(max-width:500px) {
                                border-radius: 13px;
                                -webkit-border-radius: 13px;
                                -moz-border-radius: 13px;
                                -ms-border-radius: 13px;
                                -o-border-radius: 13px;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                            }
                        }
                    }
                }
            }
        }
    }
}