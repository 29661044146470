.ContactModal{
    top: 15%;
    left: 30%;
    width: 40%;
    z-index: 12;
    display: flex;
    padding: 2.7vw;
    position: fixed;
    overflow: hidden;
    align-items: center;
    border-radius: 1.6vw;
    flex-direction: column;
    background-color: white;
    justify-content: space-between;
    @media (max-width:700px) {
        top: 25%;
        left: 15%;
        width: 70%;
        padding: 4vw;
        border-radius: 2.5vw;
    }
    @media (max-width:450px) {
        left: 10%;
        width: 80%;
        padding: 7vw 6vw;
    }
    &::before{
        left: 0;
        top: -80%;
        content: "";
        width: 100%;
        height: 150%;
        border-radius: 50%;
        position: absolute;
        background-color: #CBC09A;
    }
    h3{
        z-index: 2;
        position: sticky;
        font-size: 1.6vw;
        @media (max-width:900px) {
            font-size: 2vw;
        }
        @media (max-width:700px) {
            font-size: 4.2vw;
        }
    }
    .forms-control{
        width: 100%;
        z-index: 2;
        border: none;
        outline: none;
        padding: 1.6vw;
        position: sticky;
        margin-top: 1.5vw;
        border-radius: 1.2vw;
        background-color: #DEDEDE;
        @media (max-width:900px) {
            font-size: 14px;
        }
        @media (max-width:700px) {
            padding: 3vw;
            margin-top: 2vw;
            border-radius: 2.5vw;
        }
    }
    .red-line{
        border: solid 1px red;
    }
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number]{
        -moz-appearance: 0;
    }
    button{
        z-index: 2;
        width: 100%;
        border: none;
        outline: none;
        color: white;
        padding: 1.6vw 0;
        position: sticky;
        margin-top: 2.7vw;
        border-radius: 1.3vw;
        background-color: black;
        @media (max-width:900px) {
            font-size: 14px;
        }
        @media (max-width:700px) {
            padding: 3vw;
            margin-top: 6vw;
            border-radius: 2vw;
        }
    }
}