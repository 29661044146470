.About {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;

    .wrapper {
        padding-top: 150px;

        @media (max-width:1350px) {
            padding-top: 130px;
        }
        @media (max-width:900px) {
            padding-top: 100px;
        }

        .links {
            display: flex;
            align-items: center;
            justify-content: start;

            a {
                opacity: 0.5;
                color: black;
                text-decoration: none;
                @media (max-width:500px) {
                    font-size: 13px;
                }
                &:nth-child(2) {
                    opacity: 1;
                    margin-left: 5px;
                }
            }
        }

        .title {
            margin-top: 2vw;
            @media (max-width:500px) {
                margin-top: 4vw;
            }
        }

        .badges {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: start;
            a {
                margin-top: 2vw;
                color: black;
                padding: 5px 10px;
                margin-right: 10px;
                border-radius: 30px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                text-decoration: none;
                border: solid 1px rgb(198, 198, 198);
                &:hover{
                    color: white;
                    border: solid 1px black;
                    background-color: black;
                }
                @media (max-width:500px) {
                    font-size: 14px;
                    margin-top: 4vw;
                    padding: 3px 7px;
                    margin-right: 5px;
                }
            }
        }

        .texts {
            display: flex;
            margin-top: 3vw;
            align-items: center;
            justify-content: end;
            @media (max-width:500px) {
                margin-top: 5vw;
            }

            .col-10 {
                border-bottom: solid 1px rgb(198, 198, 198);

                @media (max-width:767px) {
                    width: 100%;
                }
            }

            .desc {
                font-size: 18px;
                font-family: CraftWork500;
                @media (max-width:1100px) {
                    font-size: 16px;
                }
                @media (max-width:500px) {
                    font-size: 14px;
                    text-align: justify;
                }
            }

            img {
                max-width: 100%;
                min-width: 100%;
                margin-top: 2vw;
                margin-bottom: 2vw;
                border-radius: 20px;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                @media (max-width:500px) {
                    margin-bottom: 4vw;
                    border-radius: 13px;
                    -webkit-border-radius: 13px;
                    -moz-border-radius: 13px;
                    -ms-border-radius: 13px;
                    -o-border-radius: 13px;
                }
            }
            .video-iframe {
                height: 35vw;
                margin-top: 2vw;
                overflow: hidden;
                border-radius: 2vw;
                border: solid 1px white;
        
                @media (max-width:767px) {
                    height: 50vw;
                    margin-top: 4vw;
                }
                @media (max-width:500px) {
                    height: 70vw;
                }
        
                iframe {
                    max-width: 100%;
                    min-width: 100%;
                    max-height: 100%;
                    min-height: 100%;
                    position: sticky;
                    z-index: 1000001;
                }
            }

            .video {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;

                .play-button {
                    z-index: 2;
                    width: 10vw;
                    height: 10vw;
                    display: flex;
                    cursor: pointer;
                    position: absolute;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    backdrop-filter: blur(10px);
                    background: rgba(255, 255, 255, 0.1);
                    @media (max-width:500px) {
                        width: 15vw;
                        height: 15vw;
                    }
                    &::before {
                        content: "";
                        width: 5.5vw;
                        height: 5.5vw;
                        position: absolute;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;
                        backdrop-filter: blur(10px);
                        background: rgba(255, 255, 255, 0.2);
                        @media (max-width:500px) {
                            width: 9vw;
                            height: 9vw;
                        }
                    }

                    .icon {
                        width: 4.5vw;
                        height: 4.5vw;
                        color: white;
                        position: absolute;
                        @media (max-width:500px) {
                            width: 9vw;
                            height: 9vw;
                        }
                    }
                }
            }
        }
    }

}
.media {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 1000001;

    .video-iframe {
        height: 30vw;
        overflow: hidden;
        border-radius: 2vw;
        border: solid 1px white;

        @media (max-width:767px) {
            width: 90%;
            height: 50vw;
            margin-top: 4vw;
        }
        @media (max-width:500px) {
            height: 70vw;
        }

        iframe {
            max-width: 100%;
            min-width: 100%;
            max-height: 100%;
            min-height: 100%;
            position: sticky;
            z-index: 1000001;
        }
    }
}