.Loader{
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    position: fixed;
    align-items: center;
    visibility: inherit;
    flex-direction: column;
    justify-content: center;
    transition: 0.5s linear;
    background-color: black;   
    .tool{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        animation-name: loaderTool;
        animation-duration: 2s;
        border: solid 10px black;
        border-top-color: white;
        border-left-color: white;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    @keyframes loaderTool {
        100%{
            transform: rotate(360deg);
        }
    }
}
.no-load{
    opacity: 0;
    visibility: hidden;
    transition: 0.5s linear;
}

