@font-face {
    font-family: CraftWork400;
    src: url(../../../assets/fonts/CraftworkGrotesk400.ttf);
}
@font-face {
    font-family: CraftWork500;
    src: url(../../../assets/fonts/CraftworkGrotesk500.ttf);
}
@font-face {
    font-family: CraftWork600;
    src: url(../../../assets/fonts/CraftworkGrotesk600.ttf);
}
@font-face {
    font-family: CraftWork700;
    src: url(../../../assets/fonts/CraftworkGrotesk700.ttf);
}
@font-face {
    font-family: CraftWork900;
    src: url(../../../assets/fonts/CraftworkGrotesk900.ttf);
}

.Calculator{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;
    .wrapper{
        .cards{
            display: flex;
            padding: 0 10px;
            justify-content: center;
            @media (max-width:767px){
                display: block;
            }
            .forms{
                padding: 15px;
                padding-left: 0px;
                @media (max-width:1200px){
                    padding: 10px;
                    padding-left: 0;                           
                }
                @media (max-width:767px){
                    width: 100%;
                    padding: 0;
                }
                .body{
                    height: 100%;
                    padding: 40px;
                    display: flex;
                    border-radius: 20px;
                    flex-direction: column;
                    align-items: flex-start;
                    background-color: #64646422;
                    justify-content: space-between;
                    @media (max-width:1200px){
                        padding: 30px;  
                        border-radius: 17px;                         
                    }
                    @media (max-width:500px){
                        padding: 20px;  
                        border-radius: 15px;                         
                    }
                    .title{
                        text-align: left;
                        padding-left: 30px;
                        @media (max-width:1200px){
                            padding-left: 20px;                           
                        }
                    }
                    .title-text{
                        text-align: left;
                        padding-left: 30px;
                        font-family: CraftWork500;
                        @media (max-width:1200px){
                            padding-left: 20px;                           
                        }
                        @media (max-width:767px){
                            font-size: 14px;                            
                        }
                        @media (max-width:600px){
                            font-size: 13px;                                                        
                        }
                    }
                    .inputs{
                        padding: 30px;
                        margin-top: 1.5vw;
                        border-radius: 20px;                    
                        background-color: #64646426;
                        @media (max-width:1200px){
                            padding: 20px;    
                            border-radius: 17px;                         
                        }
                        @media (max-width:500px){   
                            border-radius: 15px;                         
                        }
                        .forms-control{
                            width: 100%;
                            border: none;
                            outline: none;
                            padding: 25px;
                            font-size: 18px;
                            margin-top: 1.5vw;
                            border-radius: 20px;                           
                            background-color: white;
                            &::placeholder{
                                color: #6464647a;
                            }
                            @media (max-width:1400px){
                                padding: 20px 25px;                           
                                border-radius: 15px;
                            }
                            @media (max-width:900px){
                                font-size: 16px;
                                padding: 15px 20px;                           
                                border-radius: 12px;
                            }
                            @media (max-width:500px){
                                font-size: 14px;
                                margin-top: 2vw;
                                padding: 10px 20px;                           
                                border-radius: 8px;
                            }
                        }
                        .red-line{
                            border: solid 1px red;
                        }
                        input::-webkit-inner-spin-button,
                        input::-webkit-outer-spin-button{
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type=number]{
                            -moz-appearance: 0;
                        }
                        .search{
                            position: relative;
                            .forms-control{
                                margin: 0;
                                z-index: 11;
                                position: sticky;
                            }
                            .search-bar{
                                padding: 0;
                                width: 100%;
                                z-index: 11;
                                margin-top: 7px;
                                max-height: 15vw;
                                overflow-y: auto;
                                position: absolute;
                                border-radius: 15px;
                                background-color: #ececec;
                                @media (max-width:1200px){
                                    max-height: 21vw;
                                }
                                @media (max-width:1000px){
                                    font-size: 14px;
                                    border-radius: 10px;
                                }
                                @media (max-width:767px){
                                    max-height: 26vw;
                                }
                                @media (max-width:500px){
                                    font-size: 12px;
                                    max-height: 40vw;
                                    border-radius: 8px;
                                }
                                @media (max-width:430px){
                                    max-height: 190px;
                                    border-radius: 8px;
                                }
                                li{
                                    cursor: pointer;
                                    list-style: none;
                                    transition: 0.3s;
                                    padding: 5px 20px;
                                    @media (max-width:500px){
                                        padding: 5px 15px;
                                    }
                                    &:hover{
                                        padding-left: 30px;
                                        background-color: white;
                                    }
                                }
                            }
                        }
                        .size{
                            display: flex;
                            margin-top: 1.5vw;
                            position: relative;
                            align-items: center;
                            justify-content: center;
                            @media (max-width:900px){
                                margin-top: 2vw;
                            }
                            .limit{
                                width: 2px;
                                height: 80%;
                                position: absolute;
                                background-color: #64646446;
                            }
                            .height{
                                margin: 0;
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                            }
                            .width{
                                margin: 0;
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                            }
                        }
                        button{
                            border: none;
                            color: white;                           
                            margin-top: 3vw;
                            padding: 10px 50px;
                            border-radius: 9px;                            
                            background-color: rgba(0, 0, 0, 0.838);
                            &:hover{
                                color: black;
                                background-color: white;
                            }
                            @media (max-width:1100px) {
                                font-size: 15px;
                            }
                            @media (max-width:900px) {
                                padding: 9px 40px;
                                border-radius: 7px;
                                -webkit-border-radius: 7px;
                                -moz-border-radius: 7px;
                                -ms-border-radius: 7px;
                                -o-border-radius: 7px;
                            }
                            @media (max-width:700px) {
                                font-size: 13px;
                                padding: 7px 30px;
                            }
                        }
                    }
                }
            }
            .imgs{
                display: flex;
                padding-left: 20px;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                @media (max-width:1200px){
                    padding-left: 15px;                           
                }
                @media (max-width:767px){
                    padding: 0;
                    width: 100%;
                    margin-top: 4vw;
                    flex-direction: inherit;
                }
                .col-12{
                    height: 50%;
                    padding: 15px;
                    padding-right: 0;
                    @media (max-width:1200px){
                        padding: 10px 0;
                        padding-left: 5px;                           
                    }
                    @media (max-width:767px){
                        width: 47%;                        
                        padding: 0;
                    }
                    .body{
                        height: 100%;
                        padding: 20px;
                        display: flex;
                        border-radius: 20px;
                        align-items: center;
                        justify-content: center;
                        background-color: #64646422;
                        @media (max-width:1000px){
                            padding: 15px; 
                            border-radius: 15px;                                                     
                        }
                        img{
                            max-width: 100%;
                            min-width: 100%;
                            max-height: 100%;
                            min-height: 100%;
                            object-fit: cover;
                            border-radius: 20px;
                            @media (max-width:1000px){
                                border-radius: 15px;                                                     
                            }
                        }
                    }
                }
            }
        }
    }
}