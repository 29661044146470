*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html{
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}