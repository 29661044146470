@font-face {
    font-family: CraftWork400;
    src: url(../../../assets/fonts/CraftworkGrotesk400.ttf);
}
@font-face {
    font-family: CraftWork500;
    src: url(../../../assets/fonts/CraftworkGrotesk500.ttf);
}
@font-face {
    font-family: CraftWork600;
    src: url(../../../assets/fonts/CraftworkGrotesk600.ttf);
}
@font-face {
    font-family: CraftWork700;
    src: url(../../../assets/fonts/CraftworkGrotesk700.ttf);
}
@font-face {
    font-family: CraftWork900;
    src: url(../../../assets/fonts/CraftworkGrotesk900.ttf);
}
@font-face {
    font-family: Sfpro;
    src: url(../../../assets/fonts/SFPRO.ttf);
}

.Header{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    position: sticky;
    align-items: center;
    justify-content: center;
    .wrapper{
        display: flex;
        padding-top: 100px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        @media (max-width:1300px) {
            padding-top: 100px;
        } 
        @media (max-width:1000px) {
            padding-top: 80px;
        } 
        @media (max-width:600px) {
            padding-top: 100px;
        } 
        @media (max-width:450px) {
            padding-top: 90px;
        } 
        .stories-navbar{    
            padding: 0 5px;
            @media (max-width:700px){
                width: 100%;                
            }
            .story-tool{
                padding: 0 10px;
                align-items: center;
                justify-content: center;  
                display: flex !important;
                @media (max-width:500px){
                    padding: 0 5px;
                }     
                .body{
                    width: 5vw;
                    height: 5vw;
                    cursor: pointer;
                    overflow: hidden;
                    position: relative;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    border: solid 4px transparent;
                    background: linear-gradient(45deg, rgb(254, 218, 117),orange, crimson, purple, blue) border-box;
                    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0); 
                    @media (max-width:1000px) {
                        border: solid 3px transparent;
                    }
                    @media (max-width:700px) {
                        width: 11vw;
                        height: 11vw;
                    } 
                    @media (max-width:500px) {
                        width: 13vw;
                        height: 13vw;
                        border: solid 3px transparent;
                    }  
                    img{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }
                }
            }
            .slick-prev{   
                z-index: 2;                                                                                
                left: -20px;       
                width: 35px;
                height: 55px;         
                top: calc(50% - 5px);                                                       
            }
            .slick-prev:before{
                color: grey;                
                font-size: 50px;
                font-weight: 100;            
                content: "\f104";
                font-style: normal;                        
                font-family: FontAwesome; 
                @media (max-width:800px) {
                    font-size: 40px;
                }  
                @media (max-width:500px) {
                    font-size: 37px;
                }                     
            }      
            .slick-next{  
                z-index: 2;                                                                                
                width: 35px;
                height: 55px;         
                right: -20px;       
                top: calc(50% - 5px);                                                      
            }
            .slick-next:before{
                color: grey;                
                font-size: 50px;
                content: "\f105";
                font-weight: 100;            
                font-style: normal;              
                font-family: FontAwesome;    
                @media (max-width:800px) {
                    font-size: 40px;
                } 
                @media (max-width:500px) {
                    font-size: 37px;
                }               
            }
        }
        .slick-dots{            
            visibility: hidden;
        }
        .products-slider{
            margin-top: 1vw;
            .head{
                padding: 10px;
            }
            .product-tool{
                width: 100%;
                padding: 40px;
                border-radius: 19px;                
                display: flex !important;
                background-color: #64646422;
                justify-content: space-between;
                @media (max-width:1100px) {
                    padding: 30px;
                    border-radius: 15px;
                    -webkit-border-radius: 15px;
                    -moz-border-radius: 15px;
                    -ms-border-radius: 15px;
                    -o-border-radius: 15px;
                }
                @media (max-width:700px) {
                    display: grid !important;
                    grid-template-columns: repeat(1, 1fr);                    
                    grid-template-areas: "header header";
                }
                @media (max-width:450px) {
                    padding: 20px;
                    border-radius: 12px;
                    -webkit-border-radius: 12px;
                    -moz-border-radius: 12px;
                    -ms-border-radius: 12px;
                    -o-border-radius: 12px;
                }
                .texts{
                    display: flex;
                    padding-right: 5vw;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    @media (max-width:900px) {
                        padding-right: 2vw;
                    }
                    @media (max-width:700px) {
                        width: 100%;
                        margin-top: 3vw;
                        padding-right: 0;
                    }
                    .name{                        
                        font-size: 1.8vw;
                        font-weight: 600;
                        font-family: Sfpro;
                        text-transform: uppercase;                        
                        @media (max-width:1250px) {
                            font-size: 2vw;
                        }                        
                        @media (max-width:900px) {
                            font-size: 27px;
                        }
                        @media (max-width:800px) {
                            font-size: 23px;
                        }
                        @media (max-width:500px) {
                            font-size: 20px;
                        }
                    }
                    .desc{
                        font-size: 1.1vw;
                        font-family: Sfpro;
                        @media (max-width:1250px) {
                            font-size: 1.3vw;
                        }
                        @media (max-width:800px) {
                            font-size: 13px;
                        }
                    }
                    .link{
                        .explore{
                            color: black;
                            padding: 13px 50px;
                            border-radius: 9px;
                            text-decoration: none;
                            background-color: white;
                            &:hover{
                                color: white;
                                background-color: black;
                            }
                            @media (max-width:1100px) {
                                font-size: 15px;
                            }
                            @media (max-width:900px) {
                                padding: 10px 40px;
                                border-radius: 7px;
                                -webkit-border-radius: 7px;
                                -moz-border-radius: 7px;
                                -ms-border-radius: 7px;
                                -o-border-radius: 7px;
                            }
                            @media (max-width:700px) {
                                font-size: 13px;
                                padding: 10px 30px;
                            }
                        }
                    }
                }
                .imgs{
                    @media (max-width:700px) {
                        width: 100%;
                        grid-area: header;
                    }
                    .basic-img{
                        position: relative;
                        &:hover{
                            .fa-search{
                                opacity: 1;
                            }
                        }
                        .fa-search{
                            top: 0;
                            left: 0;
                            z-index: 2;
                            opacity: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            font-size: 5vw;
                            color: white;
                            cursor: pointer;
                            transition: 0.3s;
                            position: absolute;
                            border-radius: 12px;
                            align-items: center;
                            justify-content: center;
                            background-color: rgba(0, 0, 0, 0.424);
                            @media (max-width:700px) {
                                font-size: 10vw;
                                border-radius: 8px;
                                -webkit-border-radius: 8px;
                                -moz-border-radius: 8px;
                                -ms-border-radius: 8px;
                                -o-border-radius: 8px;
                            }
                        }
                    }
                    .main-image{
                        max-width: 100%;
                        min-width: 100%;
                        max-height: 18vw;
                        min-height: 18vw;
                        object-fit: cover;
                        border-radius: 12px;
                        @media (max-width:700px) {
                            max-height: 40vw;
                            min-height: 40vw;
                            border-radius: 8px;
                            -webkit-border-radius: 8px;
                            -moz-border-radius: 8px;
                            -ms-border-radius: 8px;
                            -o-border-radius: 8px;
                        }
                    }
                    .other-imgs{
                        display: flex;
                        margin-top: 2vw;
                        align-items: center;
                        justify-content: space-between;
                        .col-4{
                            &:nth-child(1){
                                padding-right: 1.5vw;
                            }
                            &:nth-child(2){
                                padding: 0 0.75vw;
                            }
                            &:nth-child(3){
                                padding-left: 1.5vw;
                            }
                            .body{
                                height: 6vw;
                                cursor: pointer;
                                overflow: hidden;
                                position: relative;
                                border-radius: 6px;
                                @media (max-width:700px){
                                    height: 15vw;
                                }
                                img{
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    object-fit: cover;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.instagram{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    z-index: 10000001;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.76);
    .icon{
        font-size: 35px;
    }
    .close-icon{
        top: 100px;
        width: 35px;
        height: 35px;
        padding: 3px;
        display: flex;
        color: white;
        cursor: pointer;
        font-size: 35px;
        border-radius: 50%;
        position: absolute;
        z-index: 1000000001;
        align-items: center;
        justify-content: center;
        border: solid 2px white;
    }
    .right-icon{
        right: 38%;
        width: 40px;
        height: 40px;
        padding: 5px;
        color: grey;
        display: flex;
        z-index: 1001;
        font-size: 30px;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        align-items: center;
        justify-content: center;
        background-color: white;
        @media (max-width:1600px) {
            right: 30%;
            font-size: 30px;
        }
        @media (max-width:1000px) {
            right: 20%;
            font-size: 30px;
        }
        @media (max-width:700px) {
            right: 15%;
            font-size: 30px;
        }
        @media (max-width:600px) {
            right: 10%;
            font-size: 30px;
        }
        @media (max-width:500px) {
            right: 5%;
            font-size: 30px;
        }
        @media (max-width:420px) {
            right: 0%;
            font-size: 30px;
        }
    }
    .left-icon{
        left: 38%;
        width: 40px;
        height: 40px;
        padding: 5px;
        color: grey;
        display: flex;
        z-index: 1001;
        font-size: 30px;
        cursor: pointer;
        position: absolute;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: white;
        @media (max-width:1600px) {
            left: 30%;
            font-size: 30px;
        }
        @media (max-width:1000px) {
            left: 20%;
            font-size: 30px;
        }
        @media (max-width:700px) {
            left: 15%;
            font-size: 30px;
        }
        @media (max-width:600px) {
            left: 10%;
            font-size: 30px;
        }
        @media (max-width:500px) {
            left: 5%;
            font-size: 30px;
        }
        @media (max-width:420px) {
            left: 0%;
            font-size: 30px;
        }
    }
    .contr-story{
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: black;
    }
}