.ScrollPercent{
    right: 2%;
    bottom: 3%;
    z-index: 20;
    width: 4.5vw;
    height: 4.5vw;
    display: flex;
    position: fixed;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50%;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    @media(max-width:800px){
        width: 7vw;
        height: 7vw;
    }
    @media(max-width:500px){
        right: 4%;
        bottom: 2%;
        width: 9vw;
        height: 9vw;
    }
    .arrow{
        margin: 0;
        width: 85%;
        height: 85%;
        display: flex;
        padding: 0.9vw;
        color: black;
        font-weight: 100;
        border-radius: 50%;
        position: absolute;
        justify-content: center;
        background-color:rgb(218, 216, 216);        
        @media(max-width:500px){
            padding: 1.5vw;
        }
    }
}
.bottom1{
    bottom: -10vw;
    transition: 0.3s;
}