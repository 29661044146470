@font-face {
    font-family: CraftWork400;
    src: url(../../../assets/fonts/CraftworkGrotesk400.ttf);
}
@font-face {
    font-family: CraftWork500;
    src: url(../../../assets/fonts/CraftworkGrotesk500.ttf);
}
@font-face {
    font-family: CraftWork600;
    src: url(../../../assets/fonts/CraftworkGrotesk600.ttf);
}
@font-face {
    font-family: CraftWork700;
    src: url(../../../assets/fonts/CraftworkGrotesk700.ttf);
}
@font-face {
    font-family: CraftWork900;
    src: url(../../../assets/fonts/CraftworkGrotesk900.ttf);
}

.HomeProducts{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;
    .wrapper{
        .title{
            text-align: center;
        }
        .cards{
            display: flex;
            flex-wrap: wrap;
            margin-top: 3vw;
            justify-content: space-between;
            @media (max-width:767px) {
                display: block;
            }
            .product{
                height: 34vw;
                padding: 10px;
                @media (max-width:1100px) {
                    padding: 5px;
                }
                @media (max-width:767px) {
                    width: 100%;
                    height: 50vw;
                    margin-top: 1.5vw;
                }
                @media (max-width:500px) {
                    height: 60vw;
                } 
                .body{
                    height: 100%;
                    padding: 20px;
                    display: flex;
                    border-radius: 9px;
                    align-items: center;
                    text-decoration: none;
                    flex-direction: column;
                    background-color: #64646422;
                    justify-content: space-between;
                    @media (max-width:1350px) {
                        padding: 15px;
                    }
                    @media (max-width:1100px) {
                        padding: 10px;
                    }
                    @media (max-width:767px) {
                        padding: 15px;
                    }
                    @media (max-width:500px) {
                        padding: 15px;
                        border-radius: 7px;
                    }
                    img{
                        max-width: 100%;
                        min-width: 100%;
                        max-height: 88%;
                        min-height: 88%;
                        object-fit: cover;
                        border-radius: 9px;    
                        @media (max-width:767px) {
                            max-height: 90%;
                            min-height: 90%;
                        }
                        @media (max-width:500px) {
                            max-height: 85%;
                            min-height: 85%;
                            border-radius: 7px;
                        }                   
                    }
                    .name{
                        margin: 0;
                        width: 100%;
                        color: black;
                        text-align: center;
                        font-family: CraftWork500;
                        &::first-letter{
                            text-transform: uppercase;
                        }
                        @media (max-width:1350px) {
                            font-size: 15px;
                        }
                        @media (max-width:1100px) {
                            font-size: 13px;
                        }
                        @media (max-width:900px) {
                            font-size: 11px;
                        }
                        @media (max-width:767px) {
                            font-size: 14px;
                        }
                        @media (max-width:500px) {
                            font-size: 13px;
                        }
                    }
                }
            }
            .standart{
                height: 17vw;
                @media (max-width:767px) {
                    height: 50vw;
                }
                @media (max-width:500px) {
                    height: 60vw;
                }
                .body{
                    img{
                        max-height: 80%;
                        min-height: 80%;
                        @media (max-width:767px) {
                            max-height: 90%;
                            min-height: 90%;
                        }
                        @media (max-width:500px) {
                            max-height: 85%;
                            min-height: 85%;
                            border-radius: 7px;
                        } 
                    }
                }
            }
            .middle{                
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                @media (max-width:767px) {
                    width: 100%;
                }
            }
        }
    }
}