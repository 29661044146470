@font-face {
    font-family: CraftWork400;
    src: url(../../../assets/fonts/CraftworkGrotesk400.ttf);
}
@font-face {
    font-family: CraftWork500;
    src: url(../../../assets/fonts/CraftworkGrotesk500.ttf);
}
@font-face {
    font-family: CraftWork600;
    src: url(../../../assets/fonts/CraftworkGrotesk600.ttf);
}
@font-face {
    font-family: CraftWork700;
    src: url(../../../assets/fonts/CraftworkGrotesk700.ttf);
}
@font-face {
    font-family: CraftWork900;
    src: url(../../../assets/fonts/CraftworkGrotesk900.ttf);
}

.HomeAbout{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;
    .wrapper{
        .cards{
            padding: 10px;
            .body{
                padding: 40px;
                border-radius: 20px;
                background-color: #64646422;
                @media (max-width:1200px){
                    padding: 30px;  
                    border-radius: 17px;                         
                }
                @media (max-width:500px){
                    padding: 20px;  
                    border-radius: 15px;                         
                }
                .titles{
                    display: flex;
                    padding: 0 10px;
                    align-items: center;
                    justify-content: space-between;
                    @media (max-width:767px) {
                        padding: 0;
                    }
                    .title{
                        margin: 0;
                        @media (max-width:450px) {
                            font-size: 17px;
                        }
                    }
                    .play{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        h4{
                            margin: 0;
                            margin-right: 20px;
                            font-family: CraftWork600;
                            @media (max-width:1100px) {
                                font-size: 18px;
                            }
                            @media (max-width:767px) {
                                font-size: 15px;
                            }
                            @media (max-width:500px) {
                                font-size: 14px;
                                margin-right: 7px;
                            }
                        }
                        img{
                            max-width: 5vw;
                            min-width: 5vw;
                            cursor: pointer;
                            @media (max-width:767px) {
                                max-width: 7vw;
                                min-width: 7vw;
                            }
                        }
                    }
                }
                .desc{
                    padding: 30px;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 2vw;                    
                    border-radius: 20px;                    
                    background-color: #64646426;
                    justify-content: space-between;
                    font-family: CraftWork500;
                    @media (max-width:1200px){
                        padding: 20px;    
                        border-radius: 17px;                         
                    }
                    @media (max-width:767px){
                        display: block;                                          
                    }
                    @media (max-width:500px){   
                        border-radius: 15px;                         
                    }
                    .img{
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                        @media (max-width:767px){
                            padding: 0;                                                        
                            width: 100%;
                        }
                        img{
                            max-width: 100%;
                            min-width: 100%;
                            border-radius: 20px;
                            @media (max-width:1200px){                                
                                border-radius: 17px;                         
                            }
                            @media (max-width:500px){   
                                border-radius: 13px;                         
                            }
                        }
                        p{
                            margin-top: 1vw;
                            @media (max-width:1100px) {
                                font-size: 14px;
                            }
                            @media (max-width:900px) {
                                font-size: 13px;
                            }
                            @media (max-width:767px) {
                                font-size: 14px;
                                margin-top: 2vw;
                            }
                            @media (max-width:500px) {
                                font-size: 12px;                                
                            }
                        }
                    }
                    .texts{
                        padding: 10px;
                        display: flex;
                        padding-left: 20px;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: space-between;
                        @media (max-width:767px){
                            padding: 0;                                                        
                            width: 100%;
                        }
                        p{
                            font-size: 18px;
                            @media (max-width:1100px) {
                                font-size: 15px;
                            }
                            @media (max-width:900px) {
                                font-size: 14px;
                            }
                            @media (max-width:500px) {
                                font-size: 13px;
                            }
                        }
                        .btns{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: start;
                            .explore{
                                color: white;
                                padding: 10px 60px;
                                border-radius: 9px;                            
                                text-decoration: none;                                
                                border: solid 1px black;
                                background-color: rgba(0, 0, 0, 0.838);
                                &:hover{
                                    color: black;
                                    background-color: white;
                                    border: solid 1px rgb(153, 153, 153);
                                }
                                @media (max-width:1100px) {
                                    font-size: 15px;
                                }
                                @media (max-width:900px) {
                                    padding: 9px 50px;
                                    border-radius: 7px;
                                    -webkit-border-radius: 7px;
                                    -moz-border-radius: 7px;
                                    -ms-border-radius: 7px;
                                    -o-border-radius: 7px;
                                }
                                @media (max-width:700px) {
                                    font-size: 13px;
                                    padding: 7px 40px;
                                }
                            }
                            .contact{
                                color: black;
                                padding: 10px 25px;
                                border-radius: 9px;                            
                                text-decoration: none;                                
                                background-color: white;
                                margin-left: 15px;
                                &:hover{
                                    color: white;
                                    background-color: black;                                    
                                }
                                @media (max-width:1100px) {
                                    font-size: 15px;
                                    margin-left: 10px;
                                }
                                @media (max-width:900px) {
                                    padding: 9px 20px;
                                    border-radius: 7px;
                                    -webkit-border-radius: 7px;
                                    -moz-border-radius: 7px;
                                    -ms-border-radius: 7px;
                                    -o-border-radius: 7px;
                                }
                                @media (max-width:700px) {
                                    font-size: 13px;
                                    padding: 7px 15px;
                                }
                            }
                        }
                    }
                    .slogan{
                        margin-top: 1vw;
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}
.media{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 1000001;
    .video{                
        height: 30vw;
        overflow: hidden;
        border-radius: 2vw;
        border: solid 1px white;
        @media (max-width:767px){
            width: 100%;
            margin-top: 4vw;
            height: 50vw;
        }                
        iframe{
            max-width: 100%;
            min-width: 100%;
            max-height: 100%;
            min-height: 100%;
            position: sticky;
            z-index: 1000001;
        }
    }
}