.IndividCeo {
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: sticky;

    .wrapper {
        padding-top: 150px;

        @media (max-width:1350px) {
            padding-top: 130px;
        }

        @media (max-width:900px) {
            padding-top: 100px;
        }

        .links {
            display: flex;
            align-items: center;
            justify-content: start;

            a {
                opacity: 0.5;
                color: black;
                text-decoration: none;
                @media (max-width:500px) {
                    font-size: 13px;
                }

                &:nth-child(2) {
                    opacity: 1;
                    margin-left: 5px;
                }
            }
        }

        .mini-title {
            opacity: 0.6;
            margin-top: 2vw;
            margin-bottom: 0;
            font-size: 2.3vw;
            font-weight: 400;
            text-align: left;            

            @media (max-width:1200px) {
                font-size: 2.8vw;
            }

            @media (max-width:700px) {
                font-size: 3.5vw;
            }

            @media (max-width:500px) {
                margin-top: 5vw;
                font-size: 4.5vw;
            }

            @media (max-width:400px) {
                font-size: 5vw;
            }

            &::first-letter {
                text-transform: uppercase;
            }
        }

        .title {
            margin-top: 1vw;
        }

        .badges {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: start;

            a {
                margin-top: 2vw;
                color: black;
                padding: 5px 10px;
                margin-right: 10px;
                border-radius: 30px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                text-decoration: none;
                border: solid 1px rgb(198, 198, 198);

                @media (max-width:500px) {
                    font-size: 14px;
                    margin-top: 2vw;
                    padding: 3px 7px;
                    margin-right: 5px;
                }
            }
        }

        .texts {
            display: flex;
            margin-top: 2vw;
            justify-content: end;

            .col-12 {
                display: flex;
                flex-wrap: wrap;
                overflow: hidden;
                padding: 30px 50px;
                position: relative;
                border-radius: 30px;
                justify-content: start;
                background-color: #ECECEC;

                @media (max-width:1100px) {
                    padding: 25px 30px;
                    border-radius: 20px;
                }

                @media (max-width:767px) {
                    width: 100%;
                    margin-top: 3vw;
                }
                @media (max-width:500px) {
                    padding: 20px;
                    border-radius: 15px;
                }

                .img {
                    z-index: 2;
                    position: sticky;
                    @media (max-width:500px) {
                        width: 55%;
                    }
                    img {
                        max-width: 100%;
                        min-width: 100%;
                        border-radius: 20px;

                        @media (max-width:1200px) {
                            border-radius: 17px;
                        }

                        @media (max-width:500px) {
                            border-radius: 13px;
                        }
                    }
                }

                .logo {
                    z-index: 2;
                    position: sticky;
                    padding: 60px 0 0 40px;
                    @media (max-width:500px) {
                        width: 45%;
                        padding: 50px 0 0 20px;
                    }
                    img {
                        max-width: 100%;
                        min-width: 100%;
                    }
                }

                .circle {
                    top: -18vw;
                    right: -5vw;
                    width: 41vw;
                    height: 41vw;
                    padding: 20px;
                    display: flex;
                    border-radius: 50%;
                    position: absolute;
                    align-items: flex-end;
                    justify-content: start;
                    background-color: #CBC09A;

                    @media (max-width:767px) {
                        top: -22vw;
                        width: 55vw;
                        height: 55vw;
                    }
                    @media (max-width:500px) {
                        top: -20vw;
                        right: -10vw;
                        width: 60vw;
                        height: 60vw;
                    }

                    img {
                        max-width: 100%;
                        min-width: 100%;
                    }
                }

                .desc {
                    margin: 0;
                    margin-top: 2vw;
                    font-size: 18px;
                    font-family: CraftWork500;

                    @media (max-width:1100px) {
                        font-size: 16px;
                    }
                    @media (max-width:500px) {
                        font-size: 14px;
                        text-align: justify;
                    }
                }
            }
        }
    }
}