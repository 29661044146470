.Contacts{
    margin: 0;
    padding: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: sticky;
    overflow: hidden;
    .wrapper{
        .titles{
            padding: 0 20px;
            @media (max-width:600px){                
                padding: 0 10px;
            }
            .title-text{
                font-size: 18px;
                margin-top: 2vw;
                font-family: CraftWork500;
                @media (max-width:1100px){
                    font-size: 16px;
                }
                @media (max-width:600px){
                    font-size: 15px;
                }
                @media (max-width:500px){
                    margin-top: 0;
                    font-size: 14px;
                    margin-bottom: 3px;
                }
            }
        }
        .forms{
            padding: 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border-radius: 20px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            background-color: #64646422;
            @media (max-width:1000px) {
                padding: 35px;
                border-radius: 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
            } 
            @media (max-width:767px) {
                padding: 25px;
                border-radius: 12px;
                -webkit-border-radius: 12px;
                -moz-border-radius: 12px;
                -ms-border-radius: 12px;
                -o-border-radius: 12px;
            }
            @media (max-width:600px) {
                padding: 20px;
                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
            } 
            @media (max-width:450px) {
                padding: 15px;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                -ms-border-radius: 8px;
                -o-border-radius: 8px;
            } 
            .cards{
                display: flex;
                padding: 40px;
                margin-top: 2vw;
                flex-wrap: wrap;
                border-radius: 20px;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                background-color: #64646422;
                justify-content: space-between;
                @media (max-width:1300px){
                    padding: 30px;
                }
                @media (max-width:767px){
                    display: block;
                }
                @media (max-width:600px){
                    padding: 20px;
                    border-radius: 10px;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -ms-border-radius: 10px;
                    -o-border-radius: 10px;
                }
                @media (max-width:450px){
                    padding: 15px;
                }
                .inputs{
                    display: flex;
                    padding-right: 30px;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    @media (max-width:767px){
                        padding: 0;
                        width: 100%;
                    }
                    .col-12{
                        display: flex;
                        @media (max-width:600px){
                            display: block;
                        }
                        .col-6{
                            &:nth-child(1){
                                padding-right: 10px;
                                @media (max-width:600px){
                                    width: 100%;
                                    padding-right: 0;
                                }
                            }
                            &:nth-child(2){
                                padding-left: 10px;
                                @media (max-width:600px){
                                    width: 100%;
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                    .forms-control{
                        width: 100%;
                        resize: none;
                        border: none;
                        outline: none;
                        font-size: 18px;
                        padding: 25px 20px;
                        margin-bottom: 2vw;
                        border-radius: 10px;
                        -webkit-border-radius: 10px;
                        -moz-border-radius: 10px;
                        -ms-border-radius: 10px;
                        -o-border-radius: 10px;
                        background-color: white;
                        &::placeholder{
                            color: #6464647a;
                        }
                        @media (max-width:1400px){
                            font-size: 16px;
                            padding: 18px 15px;
                        }
                        @media (max-width:900px){
                            font-size: 16px;
                            padding: 15px;
                        }
                        @media (max-width:600px){
                            font-size: 15px;
                            margin-bottom: 3vw;
                            padding: 15px 10px;
                            border-radius: 7px;
                            -webkit-border-radius: 7px;
                            -moz-border-radius: 7px;
                            -ms-border-radius: 7px;
                            -o-border-radius: 7px;
                        }
                    }
                    .red-line{
                        border: solid 1px red;
                    }
                    input::-webkit-inner-spin-button,
                    input::-webkit-outer-spin-button{
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    input[type=number]{
                        -moz-appearance: 0;
                    }
                    button{
                        border: none;
                        color: white;      
                        font-size: 18px;                     
                        padding: 10px 50px;
                        border-radius: 9px;                            
                        border: solid 1px black;
                        background-color: rgba(0, 0, 0, 0.838);
                        &:hover{
                            color: black;
                            background-color: rgb(255, 255, 255);
                            border: solid 1px rgb(181, 181, 181);
                        }
                        @media (max-width:1100px) {
                            font-size: 15px;
                        }
                        @media (max-width:900px) {
                            padding: 9px 40px;
                            border-radius: 7px;
                            -webkit-border-radius: 7px;
                            -moz-border-radius: 7px;
                            -ms-border-radius: 7px;
                            -o-border-radius: 7px;
                        }
                        @media (max-width:700px) {
                            font-size: 13px;
                            padding: 7px 30px;
                        }
                    }
                }
                .imgs{
                    position: relative;
                    @media (max-width:767px){
                        display: none;
                    }
                    img{
                        top: 0;
                        right: 0;
                        width: 90%;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        border-radius: 26px;
                        -webkit-border-radius: 26px;
                        -moz-border-radius: 26px;
                        -ms-border-radius: 26px;
                        -o-border-radius: 26px;
                        @media (max-width:1300px){
                            width: 100%;
                        }
                    }
                    .phone-icon{
                        top: 0;
                        right: -10px;
                        width: 5vw;
                        height: 5vw;
                        padding: 15px;
                        color: black;
                        cursor: pointer;
                        position: absolute;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        -ms-border-radius: 50%;
                        -o-border-radius: 50%;
                        background-color: white;
                        @media (max-width:1100px){
                            width: 7vw;
                            height: 7vw;
                        }
                    }
                }
            }
        }
    }
}